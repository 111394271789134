import { OpenGraphProperties } from 'open-graph-scraper';

export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  GBP = 'GBP',
}

export enum VentureStage {
  PRE_SEED = 'PRE_SEED',
  SEED = 'SEED',
  SERIES_A = 'SERIES_A',
}

export interface FileField {
  fileName?: string;
  fileSize?: number;
  id: number;
  previews?: string[];
  s3FileKey: string;
  publicFileUrl?: string;
  downloadUrl?: string;
}

export interface AmplifyCredentials {
  accessKeyId: string;
  authenticated: boolean;
  expiration: Date;
  identityId: string;
  secretAccessKey: string;
  sessionToken: string;
}

export interface UrlMetaData extends OpenGraphProperties {
  charset: string;
  favicon: string;
  requestUrl: string;
}

export enum MarketIntegrationStage {
  'POST_REVENUE' = 'POST_REVENUE',
  'PRE_PRODUCT' = 'PRE_PRODUCT',
  'PRE_REVENUE' = 'PRE_REVENUE',
}

export type UserFont = 'FREDOKA' | 'INTER' | 'OPEN_SANS' | 'PLAYFAIR';
export type AppFonts = UserFont | 'AEONIK';

export enum VentureBusinessCategory {
  'B2B' = 'B2B',
  'B2B2C' = 'B2B2C',
  'B2C' = 'B2C',
  'BTG' = 'BTG',
}

export interface EquityOfferingInvestmentCommitment {
  amount: number | string;
  comment: string;
  id: number | string;
  investorImage: FileField | null;
  investorName: string;
  linkedInUrl: string;
  websiteUrl: string;
  signingDate?: string;
  investedViaPlatform?: boolean;
  paymentConfirmed?: null | boolean;
  equityOfferingId: number;
}

export interface ClickableRow {
  clickable?: boolean;
}

export enum SupportedExternalLinkType {
  'CRUNCHBASE' = 'CRUNCHBASE',
  'DEALROOM' = 'DEALROOM',
  'LINKEDIN' = 'LINKEDIN',
  'PITCHBOOK' = 'PITCHBOOK',
  'WEBSITE' = 'WEBSITE',
}
export interface ExternalLink {
  id: number;
  linkType: SupportedExternalLinkType;
  url: string;
}

export interface InboundMessage {
  attachments: FileField[];
  body: string;
  createdAt: string;
  id: number;
  messageStatus: InboundMessageStatus;
  recipientId: number;
  sentFrom: string;
  subject: string;
}

export enum InboundMessageStatus {
  'IGNORED' = 'IGNORED',
  'IMPORTED' = 'IMPORTED',
  'READ' = 'READ',
  'RECEIVED' = 'RECEIVED',
}

export enum InitializationType {
  'GENERATED' = 'GENERATED',
  'MANUALLY_UPLOADED' = 'MANUALLY_UPLOADED',
}

export enum Status {
  'NOT_STARTED' = 'NOT_STARTED',
  'PENDING' = 'PENDING',
  'APPROVED' = 'APPROVED',
  'REJECTED' = 'REJECTED',
}
