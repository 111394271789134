import { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import Logout24Px from 'public/svg-components/Logout24Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { useLogout } from 'src/hooks/useLogout';
import { Roles, User, UserIdentification } from 'src/interfaces/user';
import { ServerSharedAQImageV2 } from 'src/server-shared/aq-image';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import {
  accreditationIsPassed,
  isPartnersInvestorPersonalDataIsFilled,
} from 'src/ui/profile-completion/minimized/investor';
import { refreshSession } from 'src/ui/auth/signup/nested-pages/registration/steps/venture-role';
import { Status } from 'src/interfaces/common';

interface SideBarUserMenuProps {
  user: User | null;
  onHide: () => void;
  role?: Roles;
  refetchUser: () => void;
  identificationDetails?: UserIdentification;
}

export const SidebarUserMenu = ({
  user,
  onHide,
  role,
  refetchUser,
  identificationDetails,
}: SideBarUserMenuProps) => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  const registrationProgress = useMemo(() => {
    if (!user) return 0;
    const isRoleAccreditationIsFinished = accreditationIsPassed(user);
    const isIdentificationIsFinished =
      identificationDetails?.verificationStatus === Status.APPROVED;
    if (isIdentificationIsFinished && isRoleAccreditationIsFinished) return 3;

    if (isIdentificationIsFinished) return 2;
    return isPartnersInvestorPersonalDataIsFilled(user) ? 1 : 0;
  }, [identificationDetails?.verificationStatus, user]);

  useEffect(() => {
    if (role === Roles.SOFT_REGISTERED_VENTURE_INVESTOR && registrationProgress === 3) {
      refreshSession();
      refetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationProgress, role]);

  return (
    <div className={classes['user-menu']} onPointerLeave={onHide}>
      <div className={classes['user-menu__menu']}>
        <div className={classes['user-menu__block']}>
          <div className="grid-row none">
            <div
              className={classNames(
                classes['user-menu__item'],
                classes['user-menu__item--soft-disabled'],
              )}
            >
              <ServerSharedAQImageV2
                image={user?.photo}
                width={24}
                height={24}
                borderRadius="round"
              >
                {!user?.photo && (
                  <ServerSharedText>
                    {(user?.firstName || '').charAt(0).toUpperCase()}
                  </ServerSharedText>
                )}
              </ServerSharedAQImageV2>

              <ServerSharedText color="body" ellipsis>
                {user?.email}
              </ServerSharedText>
            </div>
          </div>
        </div>

        <div className={classes['user-menu__footer']}>
          <div onClick={logout} className={classes['user-menu__item']}>
            <Logout24Px className={classes['stroke-style']} />
            <ServerSharedText color="body">{t('aquaty-generic.cta.logout')}</ServerSharedText>
          </div>
        </div>
      </div>
    </div>
  );
};
