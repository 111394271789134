import { useRouter } from 'next/router';

import Current24Px from 'public/svg-components/Current24Px';
import ToDo24Px from 'public/svg-components/ToDo24Px';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import TimeClockCircleM24Px from 'public/svg-components/TimeClockCircleM24Px';
import AlertCircle24Px from 'public/svg-components/AlertCircle24Px';
import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import {
  HOME_PAGE,
  INVESTMENT_EXPERIENCE_PAGE,
  IDENTIFICATION_PAGE,
  PORTFOLIO_PAGE,
} from 'src/constants/paths';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { User } from 'src/interfaces/user';
import { useSaveIdentificationDetails } from 'src/queries/users/user-identification-details.query';
import { useGetUserInvestmentAccounts } from 'src/queries/users/investment-account';
import { useGetRoleAccreditation } from 'src/queries/role-accreditation';
import { ServerSharedLoader } from 'src/server-shared/loader';
import { UserInvestmentAccount } from 'src/interfaces/profile-completion';
import { Status } from 'src/interfaces/common';
import {
  InvestorIdentificationStepCardProps,
  ProfileCompetitionContentProps,
  StepCardProps,
} from '../types';
import { fiveSeconds, tenMin } from '../shared';
import { RegistrationStep } from '../components/step';
import { IdentificationButtons } from '../components/identification-buttons';
import { RoleAccreditationDetails } from 'src/interfaces/role-accreditation';
import { accreditationIsPassed } from '../minimized/investor';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';
import { useGetUserInvestmentSpecialPurposeVehicle } from 'src/queries/venture/get-user-investment-specialpurpose-vehicle';
import { refreshSession } from 'src/ui/auth/signup/nested-pages/registration/steps/venture-role';
import { identificationIsPassed } from '../minimized/investor';
import { useGetUserIdentificationQuery } from 'src/store/user/api';
import { BeneficialOwnerStatus } from 'src/interfaces/beneficial-owner';

const isPartnersInvestorPersonalDataIsFilled = (user: User): boolean => {
  const isPersonalDataIsFilled = [user.firstName, user.lastName].every(Boolean);

  return isPersonalDataIsFilled;
};

export const hasVerifiedWallet = (userInvestmentAccounts: UserInvestmentAccount[]) =>
  userInvestmentAccounts.some((el) => el.walletDetails?.status === Status.APPROVED);

type InvestorRegistrationStage = 'PERSONAL_DATA' | 'IDENTIFICATION' | 'ACCREDITATION';
// | 'WALLET_SETUP';

const identificationIcons = {
  TODO: <ToDo24Px width={16} height={16} viewBox="0 0 24 24" />,
  INPROGRESS: <Current24Px width={16} height={16} viewBox="0 0 24 24" />,
  COMPLETED: <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
  WAITING: <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
  FAILED: <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />,
};

interface InvestorRegistrationStepsProps {
  user: User;
  refetch: () => void;
  userRoleAccreditationDetails: RoleAccreditationDetails;
  showSkipButton?: boolean;
}

export const InvestorRegistrationSteps = ({
  user,
  refetch,
  userRoleAccreditationDetails,
  showSkipButton = false,
}: InvestorRegistrationStepsProps) => {
  const { data: identificationDetails } = useGetUserIdentificationQuery(undefined);
  const { track } = useMixpanel();
  const { saveIdentification } = useSaveIdentificationDetails();
  const { push } = useRouter();

  const [showRetryButton, setShowRetryButton] = useState(true);

  const { userInvestmentAccounts } = useGetUserInvestmentAccounts({ fetchPolicy: 'no-cache' });
  const initialRefetchRef = useRef(false);

  const ventureId = useMemo(() => {
    if (!userInvestmentAccounts.length) return undefined;
    return undefined;
  }, [userInvestmentAccounts]);

  const { userInvestmentSpecialPurposeVehicle, refetch: refetchSPV } =
    useGetUserInvestmentSpecialPurposeVehicle(Number(ventureId));

  const goToAccreditation = useCallback(() => {
    push(INVESTMENT_EXPERIENCE_PAGE);
  }, [push]);

  const goToPortfolioPage = useCallback(() => {
    push(PORTFOLIO_PAGE);
  }, [push]);

  const activeStage = useMemo<InvestorRegistrationStage>(() => {
    if (!isPartnersInvestorPersonalDataIsFilled(user)) return 'PERSONAL_DATA';

    const isIdentificationIsFinished =
      identificationDetails?.verificationStatus === Status.APPROVED;
    if (!isIdentificationIsFinished) return 'IDENTIFICATION';

    const isRoleAccreditationIsFinished = accreditationIsPassed(user);
    if (!isRoleAccreditationIsFinished) return 'ACCREDITATION';
    return 'ACCREDITATION';
  }, [identificationDetails?.verificationStatus, user]);

  const personalDataStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'PERSONAL_DATA') {
      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.personal-data-preferences.subtitle',
      };
    }
    return { status: 'COMPLETED', showSubtitle: false };
  }, [activeStage]);

  const spvMembers = useMemo(() => {
    if (
      userInvestmentSpecialPurposeVehicle &&
      userInvestmentSpecialPurposeVehicle.beneficialOwners.length > 0
    ) {
      const statuses = userInvestmentSpecialPurposeVehicle.beneficialOwners.map((el) =>
        el.status ? el.status : BeneficialOwnerStatus.INVITED,
      );

      if (
        statuses.some((el) =>
          [
            BeneficialOwnerStatus.ACCOUNT_REJECTED,
            BeneficialOwnerStatus.REJECTED_BY_ADMIN,
          ].includes(el),
        )
      ) {
        return {
          status: 'FAILED',
          icon: <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />,
        };
      }
      if (
        statuses.some((el) =>
          [
            BeneficialOwnerStatus.VERIFICATION_PENDING,
            BeneficialOwnerStatus.INVITED,
            BeneficialOwnerStatus.REGISTRATION_STARTED,
            BeneficialOwnerStatus.VERIFICATION_COMPLETED,
            BeneficialOwnerStatus.VERIFICATION_FAILED,
          ].includes(el),
        )
      ) {
        return {
          status: 'WAITING',
          icon: <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
        };
      }
      return {
        status: 'COMPLETED',
        icon: <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
      };
    }
    return null;
  }, [userInvestmentSpecialPurposeVehicle]);

  const identificationStep = useMemo<InvestorIdentificationStepCardProps>(() => {
    if (activeStage === 'ACCREDITATION')
      return {
        status: spvMembers?.status === 'WAITING' ? 'WAITING' : 'COMPLETED',
        personalStatus: 'COMPLETED',
        showSubtitle: !!spvMembers,
        subtitle: (
          <AQString
            componentId="aquaty-app.profile-completion.investment-accounting-identification.success.subtitle"
            variables={{ email: user.email || '' }}
          />
        ),
      };

    if (activeStage === 'IDENTIFICATION') {
      if (identificationDetails?.verificationStatus === Status.REJECTED) {
        return {
          status: 'FAILED',
          personalStatus: 'FAILED',
          subtitle: 'aquaty-app.profile-completion.founder.identification.failed.subtitle',
        };
      }

      if (identificationDetails?.verificationStatus === Status.PENDING) {
        return {
          status: 'WAITING',
          personalStatus: 'WAITING',
          subtitle: (
            <AQString
              componentId="aquaty-app.profile-completion.investment-accounting-identification.pending.subtitle"
              variables={{ email: user.email || '' }}
            />
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        personalStatus: 'INPROGRESS',
        subtitle:
          'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      personalStatus: 'TODO',
      showSubtitle: false,
      subtitle:
        'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
    };
  }, [activeStage, identificationDetails?.verificationStatus, spvMembers, user.email]);

  const investorAccreditation = useMemo(() => {
    return user.userRoles[0];
  }, [user]);

  const accreditationStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'ACCREDITATION') {
      if (investorAccreditation.verificationStatus === Status.REJECTED) {
        return {
          status: 'FAILED',
          subtitle: (
            <ServerSharedText color="grey500" type="text-small">
              {investorAccreditation.verificationComment}
            </ServerSharedText>
          ),
        };
      }

      if (investorAccreditation.verificationStatus === Status.PENDING) {
        return {
          status: 'WAITING',
          subtitle: (
            <AQString
              componentId="aquaty-app.profile-completion.investor-accreditation.waiting.subtitle"
              variables={{ email: user.email || '' }}
            />
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      subtitle: 'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
      showSubtitle: false,
    };
  }, [activeStage, investorAccreditation, user.email]);

  useEffect(() => {
    let identificationInterval: NodeJS.Timer | undefined = undefined;
    let userInterval: NodeJS.Timer | undefined = undefined;

    const refetchData = () => {
      saveIdentification();
      if (ventureId) {
        refetchSPV();
      }
    };

    if (identificationDetails?.verificationStatus === Status.PENDING) {
      setShowRetryButton(true);
      if (!initialRefetchRef.current) {
        initialRefetchRef.current = true;
        refetchData();
      }

      identificationInterval = setInterval(() => {
        refetchData();
      }, tenMin);
      userInterval = setInterval(() => refetch(), fiveSeconds);
    } else {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    }

    return () => {
      if (identificationInterval && userInterval) {
        clearInterval(identificationInterval);
        clearInterval(userInterval);
      }
    };
  }, [
    user,
    saveIdentification,
    refetch,
    refetchSPV,
    ventureId,
    showRetryButton,
    identificationDetails?.verificationStatus,
  ]);

  useEffect(() => {
    if (
      isPartnersInvestorPersonalDataIsFilled(user) &&
      identificationIsPassed(identificationDetails) &&
      accreditationIsPassed(user)
    ) {
      track(mixpanelEvents.fullProfileCompletion, {
        ['Role']: 'venture_investor',
      });
      refreshSession();
      push(HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRoleAccreditationDetails, userInvestmentAccounts, push]);

  return (
    <div className="grid-row m">
      <RegistrationStep
        title="aquaty-app.profile-completion.personal-data-preferences.title"
        {...personalDataStep}
        index={1}
        button={
          activeStage === 'PERSONAL_DATA' ? (
            <>
              {showSkipButton && (
                <ServerSharedButton
                  label={<AQString componentId="aquaty-generic.cta.complete-profile-later" />}
                  variant="secondary"
                  onClick={goToPortfolioPage}
                />
              )}
            </>
          ) : null
        }
      />

      <RegistrationStep
        title="aquaty-app.profile-completion.investment-accounting-identification.title"
        {...identificationStep}
        index={2}
        button={
          activeStage === 'IDENTIFICATION' ? (
            <>
              {showSkipButton && (
                <ServerSharedButton
                  label={<AQString componentId="aquaty-generic.cta.complete-profile-later" />}
                  variant="secondary"
                  onClick={goToPortfolioPage}
                />
              )}
              <IdentificationButtons
                path={IDENTIFICATION_PAGE}
                showRetryButton={showRetryButton}
                status={identificationDetails?.verificationStatus}
                buttonLabel="aquaty-app.profile-completion.start-investment-account-identification"
              />
            </>
          ) : null
        }
      >
        {spvMembers && (
          <div className="grid-row xs" style={{ marginTop: '4px' }}>
            <div className="grid-column template-mc-1fr xs">
              {identificationIcons[identificationStep.personalStatus]}
              <ServerSharedText color="grey500" type="text-small">
                <AQString componentId="aquaty-app.profile-completion.investment-accounting-identification.personal" />
              </ServerSharedText>
            </div>

            <div className="grid-column template-mc-1fr xs">
              {spvMembers.icon}
              <ServerSharedText color="grey500" type="text-small">
                <AQString componentId="aquaty-app.profile-completion.investment-accounting-identification.spv" />
              </ServerSharedText>
            </div>
          </div>
        )}
      </RegistrationStep>

      <RegistrationStep
        title="aquaty-app.profile-completion.investor-accreditation.title"
        {...accreditationStep}
        index={3}
        button={
          activeStage === 'ACCREDITATION' ? (
            <>
              {showSkipButton && (
                <ServerSharedButton
                  label={<AQString componentId="aquaty-generic.cta.complete-profile-later" />}
                  variant="secondary"
                  onClick={goToPortfolioPage}
                />
              )}
              {(!investorAccreditation.verificationStatus ||
                investorAccreditation.verificationStatus === Status.NOT_STARTED) && (
                <ServerSharedButton
                  onClick={goToAccreditation}
                  label={
                    <AQString componentId="aquaty-app.profile-completion.start-investor-accreditation" />
                  }
                  variant="inform"
                />
              )}
            </>
          ) : null
        }
      />
    </div>
  );
};

export const InvestorRegistration = ({ user, refetch }: ProfileCompetitionContentProps) => {
  const { userRoleAccreditationDetails } = useGetRoleAccreditation();

  if (!user || !userRoleAccreditationDetails) {
    return <ServerSharedLoader />;
  }

  return (
    <div className="grid-row">
      <div className="grid-row xs">
        <ServerSharedText type="header">
          <AQString componentId="aquaty-app.profile-completion.founder.title" />
        </ServerSharedText>

        <ServerSharedText color="grey500" as="pre">
          <AQString
            componentId="aquaty-app.profile-completion.founder.subtitle"
            variables={{ email: user.email || '' }}
          />
        </ServerSharedText>
      </div>

      <InvestorRegistrationSteps
        user={user}
        refetch={refetch}
        userRoleAccreditationDetails={userRoleAccreditationDetails}
        showSkipButton
      />
    </div>
  );
};
