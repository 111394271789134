import { setCookies } from 'cookies-next';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { CURRENT_ROLE } from 'src/constants/registration';
import { Roles } from 'src/interfaces/user';
import { ServerSharedFeedbackModal } from 'src/server-shared/modals/feedback';
import { GeneralModalProps } from 'src/server-shared/modals/modal';

export const SwitchToIMRoleModal = ({ show, closeEvent }: GeneralModalProps) => {
  const { t } = useTranslation();

  const onConfirm = useCallback(() => {
    setCookies(CURRENT_ROLE, Roles.INVESTMENT_MANAGER);
    window.location.reload();
  }, []);
  return (
    <ServerSharedFeedbackModal
      show={show}
      closeEvent={closeEvent}
      type="success"
      title={t('aquaty-generic.menu.modal.switch-to-im-modal.title')}
      subtitle={t('aquaty-generic.menu.modal.switch-to-im-modal.subtitle')}
      footer={{
        onConfirm,
        hasLeftButton: false,
        buttonText: 'aquaty-generic.menu.modal.switch-to-im-modal.button',
      }}
    />
  );
};
