import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/interfaces/user';
import { RootState } from '..';
import initialState from '../initial-store';
import { revertAll } from '../app/reducer';

const appSlice = createSlice({
  name: 'staff-impersonate',
  initialState: initialState.staffImpersonate,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState.staffImpersonate),
  reducers: {
    setStaffImpersonate(state, { payload }: PayloadAction<{ userId: number; user: User }>) {
      state.adminView = true;
      state.userId = payload.userId;
      state.user = payload.user;
    },
    resetStaffImpersonate(state) {
      state.adminView = false;
      state.userId = null;
      state.user = null;
    },
  },
});

export const { setStaffImpersonate, resetStaffImpersonate } = appSlice.actions;

export default appSlice.reducer;

const selectSelf = (state: RootState) => state;

export const getStaffImpersonate = createSelector(
  selectSelf,
  (state: RootState) => state.staffImpersonate,
);
