/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';
import { addNotification } from '../app/reducer';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['File'],
  endpoints: (builder) => ({
    getFileDownloadUrl: builder.query<string, string>({
      query: (query) => ({
        url: `/files/download-url?${query}`,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const url = data;
          const a = document.createElement('a');
          a.href = url;
          a.download = url.split('/').pop() as string;
          document.body.appendChild(a);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.message,
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const { useLazyGetFileDownloadUrlQuery } = fileApi;
