/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import { Asset, AssetCapitalisation, AssetCustodyDetails, AssetForAPI } from 'src/interfaces/asset';

export const assetApi = createApi({
  reducerPath: 'assetApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Asset', 'Capitalisation', 'Security-Token', 'Asset-Update'],
  endpoints: (builder) => ({
    getAssets: builder.query<Asset[], null | string>({
      query: (query) => ({
        url: `/assets?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ assetId }) => ({
              type: 'Asset',
              id: assetId,
            }))
          : [],
    }),

    getAssetById: builder.query<Asset, number>({
      query: (assetId) => ({
        url: `/assets/${assetId}`,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'Asset',
                id: result.assetId,
              },
            ]
          : [],
    }),

    saveAsset: builder.mutation<Asset, Partial<Asset>>({
      query: (data) => ({
        url: '/assets',
        method: 'POST',
        data,
      }),
      invalidatesTags: (result) => [{ type: 'Asset', id: result?.assetId }],
    }),

    updateAsset: builder.mutation<Asset, Partial<AssetForAPI>>({
      query: (data) => ({
        url: `/assets/${data.assetId}`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ assetId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedAsset } = await queryFulfilled;
          dispatch(
            assetApi.util.updateQueryData('getAssetById', Number(assetId), (draft) => {
              Object.assign(draft, updatedAsset);
            }),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    getAssetCapitalisation: builder.query<AssetCapitalisation[], string>({
      query: (query) => ({
        url: `/assets/capitalisation/current?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ assetId }) => ({
              type: 'Capitalisation',
              id: assetId,
            }))
          : [],
    }),

    getAssetSecurityToken: builder.query<AssetCustodyDetails, string | number>({
      query: (assetId) => ({
        url: `/assets/${assetId}/security-token`,
      }),
      providesTags: (result) => (result ? [{ type: 'Security-Token', id: result.id }] : []),
    }),
  }),
});

export const {
  useGetAssetsQuery,
  useGetAssetByIdQuery,
  useSaveAssetMutation,
  useUpdateAssetMutation,
  useGetAssetCapitalisationQuery,
  useGetAssetSecurityTokenQuery,
} = assetApi;
