import { Currency, FileField, Status } from './common';
import { Company } from './company';

export interface IssuerGroup {
  issuerGroupId: number;
  name: string;
}

export interface Issuer {
  issuerId: number;
  name: string;
  primaryCurrencyCode: Currency;
  website: string;
  logo?: FileField;
  description: string;
  groups: IssuerGroup[];
}

export interface SecuritizationVehicle {
  securitizationVehicleId: number;
  company: Company;
  issuerId: number;
  iban: string;
  bic: string;
  taxId: string;
  verificationStatus: Status;
  updatedAt: string;
}

export interface SecuritizationVehicleForApi {
  securitizationVehicleId?: number;
  company: {
    companyId: number;
  };
  issuerId: number;
  iban: string;
  bic: string;
}

export type CreateIssuerData = Partial<Omit<Issuer, 'groups'>>;

export enum IssuerContactType {
  CONTACT = 'CONTACT',
  OWNER = 'OWNER',
}

export interface Contact {
  userId: number;
  fullName: string;
  email: string;
  photo: FileField | null;
  membershipType: IssuerContactType;
  updatedAt: string;
  groupIds: number[];
  issuerContactId: number;
}

export interface InviteContact {
  subject: string;
  message: string;
  attachments: FileField[];
  groupIds: number[];
  membershipType: IssuerContactType;
  userIds: number[];
  externalEmails: string[];
}
