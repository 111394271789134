import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';

import { isDemo } from 'src/_middleware';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { DEMO_SIGN_IN_PAGE, HOME_PAGE, SIGN_IN_PAGE, USERS_PAGE } from 'src/constants/paths';
import { useGetUser } from 'src/queries/users/get-user.query';
import { Roles } from 'src/interfaces/user';

const Index = () => {
  const router = useRouter();
  const { role } = useGetUser();
  const isLoggedIn = getCookie(COGNITO_JWT);

  useEffect(() => {
    if (isLoggedIn) {
      const paths: Record<string, string> = {
        [Roles.INVESTMENT_MANAGER]: HOME_PAGE,
        [Roles.AQUATY_STAFF]: USERS_PAGE,
        [Roles.INVESTOR]: HOME_PAGE,
        [Roles.VENTURE_FOUNDER]: HOME_PAGE,
        [Roles.SOFT_REGISTERED_VENTURE_INVESTOR]: HOME_PAGE,
      };
      if (role) router.push(paths[role]);
    } else {
      router.push(isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE);
    }
  }, [router, isLoggedIn, role]);

  return null;
};

export default Index;
