import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { DataRoom } from 'src/interfaces/data-room';
import { Notification } from 'src/shared/AqNotification';
import {
  VenturePitch,
  VenturePitchSteps,
  VenturePitchStepsForDesktopPreview,
} from 'src/interfaces/venture-pitch';
import { DataRoomSectionEvaluation } from 'src/interfaces/data-room-evaluation';
import {
  DataRoomSectionAttributeDTO,
  NotificationEvent,
  NotificationEventCluster,
} from 'src/interfaces/configuration';
import { Venture } from 'src/interfaces/venture';
import { FileField } from 'src/interfaces/common';
import { UserNavigationPathItem } from 'src/interfaces/app-navigation';
import { Roles, User } from 'src/interfaces/user';
import { PortfolioHolding } from 'src/interfaces/portfolio';

export interface SelectedVenture extends Venture {
  headerImage: FileField | null;
}

interface InitialState {
  app: {
    userNavigationItems: UserNavigationPathItem[];
    activeNavigationItem: UserNavigationPathItem;
    notifications: Notification[];
    code: number | null;
    dataRoomReviewSteps: StepIndicatorObject[];
    dataRoomEvaluationSteps: StepIndicatorObject[];
    dataRoomSectionAttributes: Record<string, DataRoomSectionAttributeDTO> | null;
    headerStepper: {
      steps: StepIndicatorObject[];
      title: string;
      titleButtonText: string;
    };
    notificationEventClusters: NotificationEventCluster[];
    notificationEvents: NotificationEvent[];
    currentRole?: Roles;
  };
  founderSharedData: {
    founderSelectedVenture: Venture | null;
    dataRoom: DataRoom | null;
    dataRoomSectionEvaluation: DataRoomSectionEvaluation[];
    venturePitchSetup: {
      venturePitch: VenturePitch | null;
      desktopPreview: {
        step: VenturePitchStepsForDesktopPreview;
        index: number;
      };
    };
  };
  staffImpersonate: {
    adminView: boolean;
    userId: number | null;
    user: User | null;
  };
  portfolio: {
    holdings: PortfolioHolding[];
    updatedAt: string;
  };
}

const initialStore: InitialState = {
  app: {
    userNavigationItems: [],
    activeNavigationItem: {
      breadcrumbs: [],
      breadcrumb: { title: '' },
      path: '/',
    },
    notifications: [],
    code: null,
    dataRoomReviewSteps: [],
    dataRoomEvaluationSteps: [],
    dataRoomSectionAttributes: null,
    headerStepper: {
      steps: [],
      title: '',
      titleButtonText: '',
    },
    notificationEventClusters: [],
    notificationEvents: [],
    currentRole: undefined,
  },
  founderSharedData: {
    founderSelectedVenture: null,
    dataRoom: null,
    dataRoomSectionEvaluation: [],
    venturePitchSetup: {
      venturePitch: null,
      desktopPreview: {
        step: VenturePitchSteps.PITCH_DESIGN,
        index: 0,
      },
    },
  },
  staffImpersonate: {
    adminView: false,
    userId: null,
    user: null,
  },
  portfolio: {
    holdings: [],
    updatedAt: '',
  },
};

export default initialStore;
