import { BeneficialOwner, BeneficialOwnerHolding } from 'src/interfaces/beneficial-owner';
/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery, http } from 'src/utils/axios';

import {
  IGetSingleUserParams,
  Roles,
  SaveUserInvestmentAccount,
  User,
  UserIdentification,
  UserInvestmentAccount,
} from 'src/interfaces/user';
import { addNotification, setCurrentRole, setUserPaths } from '../app/reducer';
import { getCookie, setCookies } from 'cookies-next';
import { CURRENT_ROLE, USER_ID } from 'src/constants/registration';
import { RoleAccreditationDetails } from 'src/interfaces/role-accreditation';
import { WalletDetails } from 'src/interfaces/profile-completion';

export const getSingleUserRole = (u: User) => {
  const isManager = u.userRoles.some((e) => e.role === Roles.INVESTMENT_MANAGER);
  return isManager ? Roles.INVESTMENT_MANAGER : u.userRoles[0]?.role;
};

const userMapper = (response: User) => {
  return { ...response, role: getSingleUserRole(response) };
};

const roleAccreditationMapper = (response: RoleAccreditationDetails) => {
  return { ...response, userRole: response.user ? getSingleUserRole(response.user) : undefined };
};

export const getUserId = () => {
  return getCookie(USER_ID);
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'User',
    'Role-Accreditation',
    'Identification-Details',
    'Investment-Accounts',
    'Beneficial-Owners',
    'Beneficial-Owners-Holding',
  ],
  endpoints: (builder) => ({
    getUser: builder.query<User, null>({
      query: () => ({
        url: `/users/${getCookie(USER_ID)}`,
      }),
      transformResponse: userMapper,
      providesTags: [{ type: 'User', id: 'currentUser' }],
      async onQueryStarted(arg, { queryFulfilled, dispatch, getState }) {
        try {
          const { data } = await queryFulfilled;
          const state = getState() as any;
          dispatch(setUserPaths((data || {}) as User));
          if (!state.app.currentRole) {
            const isAdmin = getSingleUserRole(data) === Roles.AQUATY_STAFF;

            const role = isAdmin
              ? Roles.AQUATY_STAFF
              : (getCookie(CURRENT_ROLE) as Roles | undefined);
            dispatch(setCurrentRole({ role: role ? role : getSingleUserRole(data) }));
          }
          // dispatch(setActiveNavigationItem(router.pathname));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {}
      },
    }),

    getUserById: builder.query<User, number | string>({
      query: (userId) => ({
        url: `/users/${userId}`,
      }),
      providesTags: (result) => [{ type: 'User', id: result?.userId }],
    }),

    createUser: builder.mutation<
      User,
      { user: Partial<User>; params: { invitationToken: string } | { invitationToken?: undefined } }
    >({
      query: (data) => {
        const { user, params } = data;
        return {
          url: '/users',
          method: 'POST',
          data: user,
          params,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'));
        try {
          const { data } = await queryFulfilled;
          setCookies(USER_ID, data.userId);
          dispatch(
            userApi.util.updateQueryData('getUser', null, (draft) => {
              if (draft.userId === Number(arg.user.userId)) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch (err: any) {
          // `onError` side-effect
          dispatch(
            addNotification({
              message: err?.error?.data?.toString(),
              type: 'error',
              title: 'Error',
            }),
          );
        }
      },
      invalidatesTags: [{ type: 'User', id: 'currentUser' }],
    }),

    getUsers: builder.query<User[], string>({
      query: (params) => ({
        url: `/users?${params}`,
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({ type: 'User' as const, id: el.userId })),
              { type: 'User', id: 'LIST' },
            ]
          : [{ type: 'User', id: 'LIST' }],
    }),

    updateUser: builder.mutation<User, { id: number; value: Partial<User> }>({
      query: (data) => {
        const { value } = data;
        return {
          url: `/users/${data.id}`,
          method: 'PUT',
          data: value,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data: updatedUser } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('getUser', null, (draft) => {
              Object.assign(draft, updatedUser);
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
        } catch (err: any) {
          dispatch(
            addNotification({
              title: 'aquaty-app.notifications.error',
              message: err.message,
              type: 'error',
            }),
          );
        }
      },
      transformResponse: userMapper,
      // invalidatesTags: [{ type: 'User', id: 'currentUser' }],
    }),

    getSingleUser: builder.query<User, IGetSingleUserParams>({
      query: ({ userId }) => ({
        url: `/users/${userId}`,
      }),
      providesTags: (result) => (result ? [{ type: 'User', id: result.userId }] : []),
    }),

    getUserRoleAccreditation: builder.query<
      RoleAccreditationDetails,
      { userId: string | number } | undefined
    >({
      query: (payload) => ({
        url: `/users/${payload?.userId || getCookie(USER_ID)}/role-accreditation-details`,
      }),
      transformResponse: roleAccreditationMapper,
      providesTags: (result) =>
        result ? [{ type: 'Role-Accreditation', id: 'currentUserRoleAccreditation' }] : [],
    }),

    updateRoleAccreditation: builder.mutation<
      RoleAccreditationDetails,
      { id: number; value: Partial<RoleAccreditationDetails> }
    >({
      query: (data) => {
        const { value } = data;
        return {
          url: `/users/${data.id}/role-accreditation-details`,
          method: 'PUT',
          data: value,
        };
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        // `onStart` side-effect
        // dispatch(messageCreated('Fetching post...'));
        try {
          await queryFulfilled;
        } catch (err: any) {
          // `onError` side-effect
          dispatch(
            addNotification({
              message: err?.error.data.toString(),
              type: 'error',
              title: 'Error',
            }),
          );
        }
      },
      invalidatesTags: [{ type: 'Role-Accreditation', id: 'currentUserRoleAccreditation' }],
    }),

    getUserIdentification: builder.query<
      UserIdentification,
      { userId: string | number } | undefined
    >({
      query: (payload) => ({
        url: `/users/${payload?.userId || getCookie(USER_ID)}/identification-details`,
      }),
      providesTags: (result) =>
        result ? [{ type: 'Identification-Details', id: result.identId }] : [],
    }),

    getUserInvestmentAccounts: builder.query<UserInvestmentAccount[], string | number | undefined>({
      query: (payload) => ({
        url: `/users/${payload || getCookie(USER_ID)}/investment-accounts`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el: UserInvestmentAccount) => ({
                type: 'Investment-Accounts' as const,
                id: el.investmentAccountId,
              })),
              { type: 'Investment-Accounts', id: 'LIST' },
            ]
          : [{ type: 'Investment-Accounts', id: 'LIST' }],
    }),

    saveUserInvestmentAccount: builder.mutation<
      UserInvestmentAccount,
      { userId: number; data: Partial<SaveUserInvestmentAccount> }
    >({
      query: ({ userId, data }) => {
        return {
          url: `/users/${userId}/investment-accounts`,
          method: 'POST',
          data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: newAccount } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('getUserInvestmentAccounts', undefined, (draft) => [
              ...draft,
              newAccount,
            ]),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    saveUserInvestmentAccountWallet: builder.mutation<
      UserInvestmentAccount,
      { userId: number; accountId: number; data: Partial<WalletDetails> }
    >({
      query: ({ userId, accountId, data }) => {
        return {
          url: `/users/${userId}/investment-accounts/${accountId}/wallet`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: ['Investment-Accounts'],
    }),

    saveUserInvestmentAccountWalletConfirm: builder.mutation<
      UserInvestmentAccount,
      { userId: number; accountId: number; data: number }
    >({
      query: ({ userId, accountId, data }) => {
        return {
          url: `/users/${userId}/investment-accounts/${accountId}/wallet/confirm`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: ['Investment-Accounts'],
    }),

    updateUserInvestmentAccount: builder.mutation<
      UserInvestmentAccount,
      { userId: number; investmentAccountId: number; data: Partial<SaveUserInvestmentAccount> }
    >({
      query: ({ userId, investmentAccountId, data }) => {
        return {
          url: `/users/${userId}/investment-accounts/${investmentAccountId}`,
          method: 'PUT',
          data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedInvestmentAccount } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('getUserInvestmentAccounts', undefined, (draft) =>
              [...draft].map((el) =>
                el.investmentAccountId === updatedInvestmentAccount.investmentAccountId
                  ? updatedInvestmentAccount
                  : el,
              ),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    useGetIABeneficialOwners: builder.query<
      BeneficialOwner[],
      { id: string | number; accountId: number }
    >({
      query: (payload) => ({
        url: `/users/${payload?.id}/investment-accounts/${payload?.accountId}/beneficial-owners`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el: BeneficialOwner) => ({
                type: 'Beneficial-Owners' as const,
                id: el.beneficialOwnerId,
              })),
              { type: 'Beneficial-Owners', id: 'LIST' },
            ]
          : [{ type: 'Beneficial-Owners', id: 'LIST' }],
    }),

    deleteInvestmentAccount: builder.mutation<
      UserInvestmentAccount,
      { userId: number; investmentAccountId: number }
    >({
      query: ({ userId, investmentAccountId }) => {
        return {
          url: `/users/${userId}/investment-accounts/${investmentAccountId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('getUserInvestmentAccounts', undefined, (draft) =>
              [...draft].filter((el) => el.investmentAccountId !== arg.investmentAccountId),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    resetUserIdentificationDetails: builder.mutation<
      UserIdentification,
      { id?: string | number; status: string }
    >({
      query: ({ id, status }) => ({
        url: `/users/${id || getCookie(USER_ID)}/identification-details/reset?status=${status}`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'Identification-Details', id: 'currentUserRoleAccreditation' }] : [],
    }),

    getBeneficialOwnerById: builder.query<
      BeneficialOwner,
      { id: string; verificationToken: string }
    >({
      query: ({ id, verificationToken }) => ({
        url: `/users/beneficial-owners/${id}`,
        headers: {
          Authorization: `Bearer ${verificationToken}`,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Beneficial-Owners' as const, id: result.beneficialOwnerId },
              { type: 'Beneficial-Owners', id: 'LIST' },
            ]
          : [{ type: 'Beneficial-Owners', id: 'LIST' }],
    }),

    getBeneficialOwnerHolding: builder.query<
      BeneficialOwnerHolding,
      { id: string; verificationToken: string }
    >({
      query: ({ id, verificationToken }) => ({
        url: `/users/beneficial-owners/${id}/holding`,
        headers: {
          Authorization: `Bearer ${verificationToken}`,
        },
      }),
      providesTags: (result) =>
        result ? [{ type: 'Beneficial-Owners-Holding' as const, id: result.name }] : [],
    }),

    updateBeneficialOwnerById: builder.mutation<
      BeneficialOwner,
      { data: Partial<BeneficialOwner>; verificationToken: string }
    >({
      query: ({ data, verificationToken }) => ({
        url: `/users/beneficial-owners/${data.beneficialOwnerId}`,
        headers: {
          Authorization: `Bearer ${verificationToken}`,
        },
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'Beneficial-Owners', id: result.beneficialOwnerId }] : [],
    }),

    adminUpdateBeneficialOwnerById: builder.mutation<BeneficialOwner, Partial<BeneficialOwner>>({
      query: (data) => ({
        url: `/users/beneficial-owners/${data.beneficialOwnerId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Beneficial-Owners', id: data.beneficialOwnerId }],
    }),

    getListOfInvestmentAccounts: builder.query({
      queryFn: (ids: number[]) => {
        const promises = ids.map((id) => {
          return http({
            url: `/users/${id}/investment-accounts`,
          });
        });
        return Promise.all(promises).then((results) => {
          return { data: results.map((el) => el.data).flat() };
        });
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((el: UserInvestmentAccount) => ({
                type: 'Investment-Accounts' as const,
                id: el.investmentAccountId,
              })),
              { type: 'Investment-Accounts', id: 'LIST' },
            ]
          : [{ type: 'Investment-Accounts', id: 'LIST' }],
    }),

    resetBeneficialOwnerIdentification: builder.mutation<BeneficialOwner, number>({
      query: (beneficialOwnerId) => ({
        url: `/users/beneficial-owners/${beneficialOwnerId}/identification/reset`,
        method: 'POST',
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'Beneficial-Owners', id: result.beneficialOwnerId }] : [],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserByIdQuery,
  useLazyGetUsersQuery,
  useGetUsersQuery,
  useGetSingleUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUserRoleAccreditationQuery,
  useGetUserIdentificationQuery,
  useGetUserInvestmentAccountsQuery,
  useLazyGetUserInvestmentAccountsQuery,
  useSaveUserInvestmentAccountMutation,
  useSaveUserInvestmentAccountWalletMutation,
  useSaveUserInvestmentAccountWalletConfirmMutation,
  useUpdateRoleAccreditationMutation,
  useUpdateUserInvestmentAccountMutation,
  useUseGetIABeneficialOwnersQuery,
  useDeleteInvestmentAccountMutation,
  useResetUserIdentificationDetailsMutation,
  useGetBeneficialOwnerByIdQuery,
  useUpdateBeneficialOwnerByIdMutation,
  useGetBeneficialOwnerHoldingQuery,
  useAdminUpdateBeneficialOwnerByIdMutation,
  useGetListOfInvestmentAccountsQuery,
  useResetBeneficialOwnerIdentificationMutation,
} = userApi;
