import { FC, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { getCookie } from 'cookies-next';
import { useSelector } from 'react-redux';

import {
  HOME_PAGE,
  NOT_AUTHORIZED_PAGES,
  BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
  REGISTRATION_PAGE,
  IMPERSONATOR_PAGE,
} from 'src/constants/paths';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { ApplicationSidebar } from './application-sidebar';
import { ApplicationHeader } from 'src/server-shared/header/application-header';
import { checkFinishedRegister } from './checkFinishedRegister';
import { ServerSharedLoader } from 'src/server-shared/loader';
import { PDFViewerProvider } from 'src/shared/PDfViewerV2/provider';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import { getStaffImpersonate } from 'src/store/staff-impersonate/reducer';
import { useGetUserQuery, useGetUserRoleAccreditationQuery } from 'src/store/user/api';
import { USER_ID } from 'src/constants/registration';

export const AppLayout: FC = ({ children }) => {
  const loggedUserId = getCookie(USER_ID);
  const router = useRouter();

  const { userId, adminView } = useSelector(getStaffImpersonate);
  const userRole = null;

  const { data: user, isLoading } = useGetUserQuery(null, { skip: !loggedUserId });
  const { data: userRoleAccreditationDetails } = useGetUserRoleAccreditationQuery(undefined, {
    skip:
      !loggedUserId ||
      [BENEFICIAL_OWNER_IDENTIFICATION_PAGE, REGISTRATION_PAGE, IMPERSONATOR_PAGE].includes(
        router.pathname,
      ),
  });
  const isLoggedIn = getCookie(COGNITO_JWT);

  const hideSidebar = useMemo(() => !!userId && !!adminView, [adminView, userId]);

  const isPublicPage = useMemo(() => NOT_AUTHORIZED_PAGES.includes(router.pathname), [router]);

  useEffect(() => {
    if (!isLoading && userRoleAccreditationDetails && isLoggedIn) {
      const instructions = checkFinishedRegister(
        userRoleAccreditationDetails,
        router.pathname,
        user,
      );
      if (typeof instructions === 'string') {
        router.push(instructions);
        return;
      }
      if (
        typeof instructions === 'object' &&
        instructions !== null &&
        instructions.skipAllRedirects
      ) {
        return;
      }

      if (router.pathname.includes('auth') || router.pathname === '/') {
        router.push(HOME_PAGE);
      }
    }
    if (isLoggedIn && !loggedUserId && ['/home'].includes(router.pathname)) {
      router.push(REGISTRATION_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleAccreditationDetails, userRole, user, router.pathname]);

  if (isLoading) {
    return <ServerSharedLoader />;
  }

  return isPublicPage ? (
    <>
      <Head>
        <title>AQUATY</title>
      </Head>
      <div className={classes.public}>{children}</div>
    </>
  ) : (
    <div className={classNames(classes.app, { [classes['hide-sidebar']]: hideSidebar })}>
      {!hideSidebar && <ApplicationSidebar />}
      <ApplicationHeader hideSidebar={hideSidebar} />
      <PDFViewerProvider>
        <main className={classes.app__main}>{children}</main>
      </PDFViewerProvider>
    </div>
  );
};
