/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import { Roles, User } from 'src/interfaces/user';
import { getCookie } from 'cookies-next';
import { USER_ID } from 'src/constants/registration';

import {
  Contact,
  InviteContact,
  Issuer,
  SecuritizationVehicle,
  SecuritizationVehicleForApi,
} from 'src/interfaces/issuer';

export const getSingleUserRole = (u: User) => {
  const isManager = u.userRoles.some((e) => e.role === Roles.INVESTMENT_MANAGER);
  return isManager ? Roles.INVESTMENT_MANAGER : u.userRoles[0]?.role;
};

export const getUserId = () => {
  return getCookie(USER_ID);
};

export const issuerApi = createApi({
  reducerPath: 'issuerApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Issuer', 'Securitization-Vehicle', 'Contact'],
  endpoints: (builder) => ({
    getIssuers: builder.query<Issuer[], null>({
      query: () => ({
        url: '/issuers',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Issuer' as const,
                id: el.issuerId,
              })),
              { type: 'Issuer', id: 'LIST' },
            ]
          : [{ type: 'Issuer', id: 'LIST' }],
    }),

    getIssuerById: builder.query<Issuer, number>({
      query: (id) => ({
        url: `/issuers/${id}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => (result ? [{ type: 'Issuer', id: result.issuerId }] : []),
    }),

    saveIssuer: builder.mutation<Issuer, { data: Partial<Issuer> }>({
      query: ({ data }) => {
        return {
          url: '/issuers',
          method: 'POST',
          data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: newIssuer } = await queryFulfilled;
          dispatch(
            issuerApi.util.updateQueryData('getIssuers', null, (draft) => [...draft, newIssuer]),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    updateIssuer: builder.mutation<Issuer, { data: Partial<Issuer> }>({
      query: ({ data }) => {
        return {
          url: `/issuers/${data.issuerId}`,
          method: 'PUT',
          data,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedIssuer } = await queryFulfilled;
          dispatch(
            issuerApi.util.updateQueryData('getIssuers', null, (draft) =>
              [...draft].map((el) => (el.issuerId === updatedIssuer.issuerId ? updatedIssuer : el)),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    getIssuerSecuritizationVehicles: builder.query<SecuritizationVehicle[], number | undefined>({
      query: (issuerId) => ({
        url: `/issuers/${issuerId}/securitization-vehicles`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Securitization-Vehicle' as const,
                id: el.securitizationVehicleId,
              })),
              { type: 'Securitization-Vehicle', id: 'LIST' },
            ]
          : [{ type: 'Securitization-Vehicle', id: 'LIST' }],
    }),

    getIssuerSecuritizationVehicleById: builder.query<
      SecuritizationVehicle,
      { issuerId?: number; spvId?: number }
    >({
      query: ({ issuerId, spvId }) => ({
        url: `issuers/${issuerId}/securitization-vehicles/${spvId}`,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: 'Securitization-Vehicle' as const,
                id: result.securitizationVehicleId,
              },
              { type: 'Securitization-Vehicle', id: 'LIST' },
            ]
          : [{ type: 'Securitization-Vehicle', id: 'LIST' }],
    }),

    saveIssuerSecuritizationVehicles: builder.mutation<
      SecuritizationVehicle,
      { issuerId: number; data: Partial<SecuritizationVehicleForApi> }
    >({
      query: ({ issuerId, data }) => ({
        url: `/issuers/${issuerId}/securitization-vehicles`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: newSecuritizationVehicle } = await queryFulfilled;
          dispatch(
            issuerApi.util.updateQueryData(
              'getIssuerSecuritizationVehicles',
              arg.issuerId,
              (draft) => [...draft, newSecuritizationVehicle],
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    updateIssuerSecuritizationVehicles: builder.mutation<
      SecuritizationVehicle,
      {
        issuerId: number;
        securitizationVehicleId: number;
        data: Partial<SecuritizationVehicleForApi>;
      }
    >({
      query: ({ issuerId, securitizationVehicleId, data }) => ({
        url: `/issuers/${issuerId}/securitization-vehicles/${securitizationVehicleId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result) => [
        { type: 'Securitization-Vehicle', id: result?.securitizationVehicleId || 'LIST' },
      ],
    }),

    deleteIssuerSecuritizationVehicles: builder.mutation<
      null,
      {
        issuerId: number;
        securitizationVehicleId: number;
      }
    >({
      query: ({ issuerId, securitizationVehicleId }) => ({
        url: `/issuers/${issuerId}/securitization-vehicles/${securitizationVehicleId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            issuerApi.util.updateQueryData(
              'getIssuerSecuritizationVehicles',
              arg.issuerId,
              (draft) =>
                [...draft].filter(
                  (el) => el.securitizationVehicleId !== arg.securitizationVehicleId,
                ),
            ),
          );
        } catch (err) {
          console.log('err', err);
        }
      },
    }),

    getIssuerContacts: builder.query<Contact[], number>({
      query: (id) => ({
        url: `/issuers/${id}/contacts?membershipType=CONTACT`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Contact' as const,
                id: el.userId,
              })),
              { type: 'Contact', id: 'LIST' },
            ]
          : [{ type: 'Contact', id: 'LIST' }],
    }),

    getIssuerContactsWithParams: builder.query<Contact[], { id: number; params: string }>({
      query: ({ id, params }) => ({
        url: `/issuers/${id}/contacts?${params}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Contact' as const,
                id: el.userId,
              })),
              { type: 'Contact', id: 'LIST' },
            ]
          : [{ type: 'Contact', id: 'LIST' }],
    }),

    getIssuerOwners: builder.query<Contact[], number>({
      query: (id) => ({
        url: `/issuers/${id}/contacts?membershipType=OWNER`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map((el) => ({
                type: 'Contact' as const,
                id: el.userId,
              })),
              { type: 'Contact', id: 'LIST' },
            ]
          : [{ type: 'Contact', id: 'LIST' }],
    }),

    saveIssuerContact: builder.mutation<
      Contact,
      { issuerId: number; data: Partial<InviteContact> }
    >({
      query: ({ data, issuerId }) => {
        return {
          url: `/issuers/${issuerId}/contacts`,
          method: 'POST',
          data,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'Contact' as const,
                id: result.userId,
              },
              { type: 'Contact', id: 'LIST' },
            ]
          : [{ type: 'Contact', id: 'LIST' }],
    }),

    updateIssuerContact: builder.mutation<Contact, { issuerId: number; data: Partial<Contact> }>({
      query: ({ data, issuerId }) => {
        return {
          url: `/issuers/${issuerId}/contacts/${data.issuerContactId}`,
          method: 'PUT',
          data,
        };
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'Contact' as const,
                id: result.userId,
              },
              { type: 'Contact', id: 'LIST' },
            ]
          : [{ type: 'Contact', id: 'LIST' }],
    }),

    deleteIssuerContact: builder.mutation<Contact, { issuerId: number; contactId: number }>({
      query: ({ issuerId, contactId }) => {
        return {
          url: `/issuers/${issuerId}/contacts/${contactId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'Contact' as const }],
    }),
  }),
});

export const {
  useGetIssuersQuery,
  useGetIssuerByIdQuery,
  useSaveIssuerMutation,
  useUpdateIssuerMutation,
  useGetIssuerSecuritizationVehiclesQuery,
  useGetIssuerSecuritizationVehicleByIdQuery,
  useSaveIssuerSecuritizationVehiclesMutation,
  useUpdateIssuerSecuritizationVehiclesMutation,
  useDeleteIssuerSecuritizationVehiclesMutation,
  useGetIssuerContactsQuery,
  useLazyGetIssuerContactsWithParamsQuery,
  useGetIssuerOwnersQuery,
  useLazyGetIssuerContactsQuery,
  useSaveIssuerContactMutation,
  useUpdateIssuerContactMutation,
  useDeleteIssuerContactMutation,
} = issuerApi;
