import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { ServerSharedText } from 'src/server-shared/texts/text';
import classes from './index.module.scss';
import { RoleAssignment, Roles, User } from 'src/interfaces/user';
import CheckCirclePrimary24Px from 'public/svg-components/CheckCirclePrimary24Px';
import AddCircle24Px from 'public/svg-components/AddCircle24Px';
import { setCookies } from 'cookies-next';
import { CURRENT_ROLE, INVESTOR_ROLE_REQUEST } from 'src/constants/registration';
import { PROFILE_COMPLETION_PAGE } from 'src/constants/paths';
import { useToggle } from 'src/hooks/useToggle';
import { SwitchRoleModal } from 'src/ui/menu/modals/SwitchRoleModal';
import { useUpdateUserMutation } from 'src/store/user/api';
import { getCurrentRole, setActiveNavigationItem, setCurrentRole } from 'src/store/app/reducer';

interface SwitchRoleMenuProps {
  user: User | null;
  onHide: () => void;
  toggleSwitchToIMModal: () => void;
}

const roles = [
  {
    role: Roles.INVESTOR,
    title: 'aquaty-app.admin.filter.investor',
    addRole: 'Add Investor Role',
  },
  {
    role: Roles.INVESTMENT_MANAGER,
    title: 'aquaty-app.admin.filter.investment-manager',
    addRole: 'Add Investment Manager Role',
  },
];

export const SwitchRoleMenu = ({ user, onHide, toggleSwitchToIMModal }: SwitchRoleMenuProps) => {
  const { t } = useTranslation();
  const { push, pathname } = useRouter();
  const dispatch = useDispatch();

  const [saveUser, { isLoading: saveUserLoading }] = useUpdateUserMutation();
  const role = useSelector(getCurrentRole);

  const [showSwitchRoleModal, toggleSwitchRoleModal] = useToggle();

  const switchRoleHandler = useCallback(
    async (hasRole: boolean, role: Roles, isActive: boolean) => {
      if (isActive && saveUserLoading) {
        return;
      }
      if (!hasRole) {
        if (role === Roles.INVESTMENT_MANAGER && user) {
          await saveUser({
            id: user.userId,
            value: {
              ...user,
              userRoles: [...user.userRoles, { role } as RoleAssignment],
            },
          });
          toggleSwitchToIMModal();
          return;
        }
        if (role === Roles.INVESTOR && user) {
          await saveUser({
            id: user.userId,
            value: {
              ...user,
              userRoles: [...user.userRoles, { role } as RoleAssignment],
            },
          });
          setCookies(INVESTOR_ROLE_REQUEST, true);
          push(PROFILE_COMPLETION_PAGE);
        }
      }
      setCookies(CURRENT_ROLE, role);
      dispatch(setCurrentRole({ role }));
      dispatch(setActiveNavigationItem(pathname));
    },
    [dispatch, push, saveUser, saveUserLoading, toggleSwitchToIMModal, user, pathname],
  );

  if (!user) {
    return null;
  }

  return (
    <div className={classes['switch-role']} onPointerLeave={onHide}>
      <div className={classes['switch-role__menu']}>
        {roles.map((el) => {
          const hasRole = user.userRoles.some((roleAssignment) => roleAssignment.role === el.role);
          const isActive = role === el.role;
          return (
            <div
              key={el.role}
              onClick={() => switchRoleHandler(hasRole, el.role, isActive)}
              className={classes['switch-role__item']}
            >
              <ServerSharedText color="body">{t(hasRole ? el.title : el.addRole)}</ServerSharedText>
              {isActive && <CheckCirclePrimary24Px />}
              {!hasRole && <AddCircle24Px className={classes['add-icon']} />}
            </div>
          );
        })}
      </div>

      {showSwitchRoleModal && <SwitchRoleModal show closeEvent={toggleSwitchRoleModal} />}
    </div>
  );
};
