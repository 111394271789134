/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import { Company } from 'src/interfaces/company';
import { BeneficialOwner } from 'src/interfaces/beneficial-owner';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Company', 'Beneficial-Owners'],
  endpoints: (builder) => ({
    getCompaniesRegistry: builder.query<
      Company[],
      { registrationCountry: string; registrationName: string }
    >({
      query: (query) => ({
        url: '/companies/registry',
        query,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ companyId }) => ({
              type: 'Company',
              id: companyId,
            }))
          : [],
    }),

    getCompanies: builder.query<Company[], null>({
      query: () => ({
        url: '/companies',
      }),
      providesTags: (result) =>
        result
          ? result.map(({ companyId }) => ({
              type: 'Company',
              id: companyId,
            }))
          : [],
    }),

    saveCompany: builder.mutation<Company, Partial<Company>>({
      query: (data) => ({
        url: '/companies',
        method: 'POST',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'Company',
                id: result.companyId,
              },
            ]
          : [],
    }),

    getCompaniesBeneficialOwners: builder.query<BeneficialOwner[], { id: number }>({
      query: ({ id }) => ({
        url: `/companies/${id}/beneficial-owners`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ beneficialOwnerId }) => ({
              type: 'Beneficial-Owners',
              beneficialOwnerId,
            }))
          : [],
    }),
  }),
});

export const {
  useLazyGetCompaniesQuery,
  useLazyGetCompaniesRegistryQuery,
  useSaveCompanyMutation,
  useGetCompaniesBeneficialOwnersQuery,
} = companyApi;
