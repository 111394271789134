import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { TFunction, useTranslation } from 'next-i18next';

import LockFilled16Px from 'public/svg-components/LockFilled16Px';
import ChevronRight from 'public/svg-components/ChevronRight';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { getActiveNavigationItem, setActiveNavigationItem } from 'src/store/app/reducer';
import { PathItem } from 'src/interfaces/app-navigation';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';

const getQuery = (queries: Record<string, any>, q?: (number | string)[]): Record<string, any> => {
  if (!q || q.length === 0) return {};
  return q.reduce((acc, el) => {
    if (queries[el]) return { ...acc, [el]: queries[el] };
    return acc;
  }, {});
};

const getBreadcrumbText = ({ breadcrumb }: PathItem, t: TFunction) => {
  return (
    <ServerSharedText type="label-s" color="grey500" style={{ padding: '4px 8px' }}>
      {breadcrumb.isDynamic ? breadcrumb.title || ' ' : t(breadcrumb.title)}
    </ServerSharedText>
  );
};

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const activeNavigationItem = useSelector(getActiveNavigationItem);

  useEffect(() => {
    if (activeNavigationItem.path !== router.pathname) {
      dispatch(setActiveNavigationItem(router.pathname));
    }
  }, [router.pathname, dispatch, activeNavigationItem.path]);

  const breadcrumbsWithTitle = useMemo(() => {
    return activeNavigationItem.breadcrumbs.filter((el) => !!el.breadcrumb.title);
  }, [activeNavigationItem.breadcrumbs]);

  const tag = useMemo(() => activeNavigationItem.headerTag, [activeNavigationItem]);

  return (
    <ul className={classes.list}>
      {breadcrumbsWithTitle.map((bc, i) => {
        const breadcrumbText = getBreadcrumbText(bc, t);
        const isLast = activeNavigationItem.breadcrumbs.length === i + 1;
        return (
          <li className={classes.list__item} key={bc.path}>
            {i !== 0 && !!bc.breadcrumb.title && (
              <div className={classes.chevron}>
                <ChevronRight />
              </div>
            )}
            {isLast || bc.isNotLink ? (
              breadcrumbText
            ) : (
              <Link
                href={{
                  pathname: bc.path,
                  query: getQuery(router.query, bc.breadcrumb.query),
                }}
              >
                {breadcrumbText}
              </Link>
            )}
          </li>
        );
      })}

      {tag && tag.show && (
        <>
          {tag.type === 'public' && (
            <li className="tag-item flex xxs">
              <LockFilled16Px />
              <ServerSharedText type="label-xs">{t(tag.text || '')}</ServerSharedText>
            </li>
          )}
          {tag.type === 'private' && (
            <li className="tag-item flex xxs">
              <LockFilled16Px className={classes.lockFilled} />
              <ServerSharedText type="label-xs" color="grey500">
                {t(tag.text || '').toUpperCase()}
              </ServerSharedText>
            </li>
          )}
          {/* we can define a list of tags here  */}
        </>
      )}
    </ul>
  );
};

export const ExtendedBreadcrumbs = () => {
  return (
    <div className={classNames('flex justify-content-sp', classes.wrapper)}>
      <Breadcrumbs />
    </div>
  );
};
