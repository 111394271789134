/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';
import { PortfolioData } from 'src/interfaces/portfolio';

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Portfolio'],
  endpoints: (builder) => ({
    getUnrealizedPortfolioHoldings: builder.query<PortfolioData, null>({
      query: () => ({
        url: '/portfolio/unrealised',
      }),
      providesTags: (result) =>
        result
          ? result.holdings.map(({ assetId }) => ({
              type: 'Portfolio',
              id: assetId,
            }))
          : [],
    }),

    getManagedPortfolioHoldings: builder.query<PortfolioData, null>({
      query: () => ({
        url: '/portfolio/managed',
      }),
      providesTags: (result) =>
        result
          ? result.holdings.map(({ assetId }) => ({
              type: 'Portfolio',
              id: assetId,
            }))
          : [],
    }),
  }),
});

export const { useGetUnrealizedPortfolioHoldingsQuery, useGetManagedPortfolioHoldingsQuery } =
  portfolioApi;
