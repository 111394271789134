import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import rootReducer from './root-reducer';
import { userApi } from './user/api';
import { issuerApi } from './issuer/api';
import { companyApi } from './company/api';
import { investmentOfferingApi } from './investment-offering/api';
import { assetApi } from './asset/api';
import { adminApi } from './admin/api';
import { contractApi } from './contract/api';
import { fileApi } from './file/api';
import { portfolioApi } from './portfolio/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleWares: any[] = [
  userApi.middleware,
  issuerApi.middleware,
  companyApi.middleware,
  investmentOfferingApi.middleware,
  assetApi.middleware,
  adminApi.middleware,
  contractApi.middleware,
  fileApi.middleware,
  portfolioApi.middleware,
];

if (process.env.NODE_ENV === 'development') {
  middleWares.push(createLogger({ collapsed: true }));
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleWares),
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
