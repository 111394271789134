import { UserHoldingRole } from './profile-completion';
import { RoleAccreditationDetails } from './role-accreditation';
import { User, UserIdentification } from './user';
import { CompanyAssociatedNaturalPersonBasic } from './venture';

export enum BeneficialOwnerStatus {
  INVITED = 'INVITED',
  REGISTRATION_STARTED = 'REGISTRATION_STARTED',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  VERIFICATION_COMPLETED = 'VERIFICATION_COMPLETED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  ACCOUNT_APPROVED = 'ACCOUNT_APPROVED',
  ACCOUNT_REJECTED = 'ACCOUNT_REJECTED',
  REJECTED_BY_ADMIN = 'REJECTED_BY_ADMIN',
}

export interface BeneficialOwner
  extends Pick<
      User,
      | 'birthDate'
      | 'birthPlace'
      | 'city'
      | 'country'
      | 'email'
      | 'street'
      | 'zip'
      | 'phoneNumber'
      | 'phoneNumberCountryCode'
      | 'nationality'
      | 'middleName'
    >,
    Pick<
      RoleAccreditationDetails,
      | 'capitalMarketExperience'
      | 'capitalMarketExperienceType'
      | 'otherCapitalMarketExperienceType'
      | 'previousTokenizationExperience'
      | 'regulatoryConfirmations'
      | 'tokenizationExperiencePeriod'
      | 'tokenizationInvestmentAmount'
      | 'tokenizationInvestmentFrequency'
    >,
    CompanyAssociatedNaturalPersonBasic,
    Pick<UserIdentification, 'identId' | 'identificationLink'> {
  updatedAt: string;
  beneficialOwnerId: number;
  investmentHoldingId: number;
  userId: number | null;
  status: BeneficialOwnerStatus;
  holdingParticipationRole: UserHoldingRole;
}

export interface BeneficialOwnerHolding {
  name: string;
  holdingParticipationRole: UserHoldingRole;
  address: {
    street: string;
    city: string;
    zipCode: string;
    country: string;
  };
  registeredBy: string;
}
