import { useMemo, useState } from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';

import { AQSquareLogo } from 'src/shared/AQSquareLogo';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import { Roles } from 'src/interfaces/user';
import { NavItem } from './nav-item';
import { getConfigByUser } from 'src/constants/navigation';
import { SidebarUserMenu } from './sidebar-user-menu';
import classes from './index.module.scss';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import { classNames } from 'src/utils/classNames';
import SwitchRole24Px from 'public/svg-components/SwitchRole24Px';
import { SwitchRoleMenu } from './switch-role-menu';
import { useGetUserIdentificationQuery, useGetUserQuery } from 'src/store/user/api';
import { SwitchToIMRoleModal } from 'src/ui/menu/modals/SwitchToIMRole';
import { useToggle } from 'src/hooks/useToggle';
import { getCurrentRole } from 'src/store/app/reducer';

export const ApplicationSidebar = () => {
  const { data: user = null, refetch } = useGetUserQuery(null);
  const { data: identificationDetails } = useGetUserIdentificationQuery(undefined);

  const currentRole = useSelector(getCurrentRole);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showSwitchRoleMenu, setShowSwitchRoleMenu] = useState(false);
  const [showSwitchToIMModal, toggleSwitchToIMModal] = useToggle();

  const userSideMenu = useMemo(() => {
    if (!user) return [];
    return getConfigByUser(user, currentRole).sidebar;
  }, [currentRole, user]);

  const height = useMemo(() => 48 * userSideMenu.length, [userSideMenu.length]);

  return userSideMenu.length === 0 ? null : (
    <nav className={classes['side-bar-container']}>
      <div className={classes['side-bar-container__static']}>
        {currentRole === Roles.AQUATY_STAFF ? (
          <div className={classes['side-bar-container__logo-anchor']}>
            <AQSquareLogo />
          </div>
        ) : (
          <Link href="/home" legacyBehavior passHref>
            <div className={classes['side-bar-container__logo-anchor']}>
              <AQSquareLogo />
            </div>
          </Link>
        )}

        <ul className={classes['side-bar-container__menu']} style={{ height }}>
          {userSideMenu.map((item) => (
            <NavItem key={item.path} {...item} />
          ))}
        </ul>

        <div className={classNames('grid-row xs', classes['side-bar-container__user'])}>
          {currentRole !== Roles.AQUATY_STAFF && (
            <div
              className={classNames('flex', classes['switch-role-wrapper'])}
              onClick={() => setShowSwitchRoleMenu(true)}
            >
              <SwitchRole24Px />
            </div>
          )}
          <div
            className={classNames('flex', classes['side-bar-container__user-avatar'])}
            onClick={() => setShowUserMenu(true)}
          >
            <ServerSharedAQImageWithChildren
              image={user?.photo}
              width={40}
              height={40}
              borderRadius="round"
              placeholderIcon={<UserPlaceholderIcon fullName={user?.firstName} />}
            />
          </div>
          {showUserMenu && (
            <SidebarUserMenu
              user={user}
              role={currentRole}
              onHide={() => setShowUserMenu(false)}
              refetchUser={refetch}
              identificationDetails={identificationDetails}
            />
          )}

          {showSwitchRoleMenu && (
            <SwitchRoleMenu
              user={user}
              onHide={() => setShowSwitchRoleMenu(false)}
              toggleSwitchToIMModal={toggleSwitchToIMModal}
            />
          )}
          {showSwitchToIMModal && <SwitchToIMRoleModal show closeEvent={toggleSwitchToIMModal} />}
        </div>
      </div>
    </nav>
  );
};
