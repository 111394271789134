import { createPortal } from 'react-dom';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';

import ViewOff from 'public/svg-components/ViewOff';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

export interface SingleTopNotificationProps {
  open: boolean;
  text?: string | JSX.Element;
  top?: number;
  rightElement?: JSX.Element;
  icon?: React.ElementType;
  iconProps?: any;
  rotateIcon?: boolean;
  inModal?: boolean;
}

const max_width = 560;

export const SingleTopNotification = ({
  open,
  text,
  top = 16,
  rightElement,
  icon: Icon = ViewOff,
  rotateIcon = false,
  inModal = false,
  iconProps = {},
}: SingleTopNotificationProps) => {
  const { t } = useTranslation();
  const { className: iconClassName, ...rest } = iconProps;

  const notificationContainerRef = useRef<HTMLDivElement>(null);
  const notificationContentRef = useRef<HTMLDivElement>(null);

  // 560px - max ribbon width
  // 32px - side paddings (16 + 16)
  // 24px - icon
  // 32px - spacing between elements
  const maxWidth = max_width - 32 - 24 - 32;

  useLayoutEffect(() => {
    if (notificationContainerRef.current && notificationContentRef.current) {
      const dimensions = notificationContentRef.current.getBoundingClientRect();
      notificationContainerRef.current.style.left = `calc(50% - (${dimensions.width}px / 2))`;
      notificationContainerRef.current.style.maxWidth = `${max_width}px`;
    }
  }, [open]);

  return createPortal(
    <div
      className={classNames(classes.container)}
      style={{ top, zIndex: inModal ? 100500 : 6 }}
      ref={notificationContainerRef}
    >
      <div
        className={classNames(classes.box, {
          [classes['box__with-right-button']]: !!rightElement,
          [classes['box--show']]: open,
          [classes['box--hide']]: open === false,
        })}
        ref={notificationContentRef}
      >
        <Icon
          className={classNames(iconClassName, {
            [classes.container__icon]: rotateIcon,
          })}
          {...rest}
        />
        <ServerSharedText type="label" color="body" ellipsis style={{ maxWidth }}>
          {typeof text === 'string' && text.includes('aquaty-') ? t(text) : text}
        </ServerSharedText>

        {rightElement}
      </div>
    </div>,
    document.body,
  );
};
