import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from 'src/hooks/useMixpanel';
import { Status } from 'src/interfaces/common';
import { ERoleAccreditationStatus } from 'src/interfaces/user';

export const oneMin = 1000 * 60;
export const tenMin = 1000 * 60 * 10;
export const fiveSeconds = 1000 * 5;

export const investmentAccountIdentificationSubtitle = {
  [Status.NOT_STARTED]:
    'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
  [Status.REJECTED]:
    'aquaty-app.profile-completion.investment-accounting-identification.failed.subtitle',
  [Status.PENDING]:
    'aquaty-app.profile-completion.investment-accounting-identification.pending.subtitle',
  [Status.APPROVED]:
    'aquaty-app.profile-completion.investment-accounting-identification.success.subtitle',
};

export const investorAccreditationSubtitle = {
  [ERoleAccreditationStatus.NOT_STARTED]:
    'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
  [ERoleAccreditationStatus.WAITING]:
    'aquaty-app.profile-completion.investor-accreditation.waiting.subtitle',
  [ERoleAccreditationStatus.APPROVED]: '',
  [ERoleAccreditationStatus.REFUSED]: '',
};

export const contactSupportHandler = () => {
  if (['prod', 'staging'].includes(process.env.RUNTIME_ENVIRONMENT || '')) {
    window.HubSpotConversations.widget.open();
    return;
  }
  mixpanel.track(mixpanelEvents.contactSupport);
  const anchor = document.createElement('a');
  anchor.href = 'mailto:support@aquaty.vc?subject="User Issue"';
  anchor.click();
};

export const emailUsHandler = () => {
  mixpanel.track(mixpanelEvents.contactSupport);
  const anchor = document.createElement('a');
  anchor.href = 'mailto:support@aquaty.vc?subject="User Issue"';
  anchor.click();
};
