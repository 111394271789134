/* eslint-disable indent */
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/utils/axios';

import { ConcedusProductImport, InvestmentOffering } from 'src/interfaces/investment-offering';
import { User } from 'src/interfaces/user';
import { Issuer } from 'src/interfaces/issuer';
import { Status } from 'src/interfaces/common';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Offering', 'User', 'Issuer', 'Liability-Roof-Import'],
  endpoints: (builder) => ({
    getUsers: builder.query<User[], string>({
      query: (query) => ({
        url: `/admin/users?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ userId }) => ({
              type: 'User',
              id: userId,
            }))
          : [],
    }),

    getIssuers: builder.query<Issuer[], string>({
      query: (query) => ({
        url: `/admin/issuers?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ issuerId }) => ({
              type: 'Issuer',
              id: issuerId,
            }))
          : [],
    }),

    getInvestmentOfferings: builder.query<InvestmentOffering[], string>({
      query: (query) => ({
        url: `/admin/investment-offerings?${query}`,
      }),
      providesTags: (result) =>
        result
          ? result.map(({ investmentOfferingId }) => ({
              type: 'Offering',
              id: investmentOfferingId,
            }))
          : [],
    }),

    updateSPVStatus: builder.mutation<
      boolean,
      { statue: Status; securitizationVehicleId: number; comment?: string }
    >({
      query: (data) => ({
        url: '/admin/issuer-securitization-vehicle-status',
        method: 'PUT',
        data,
      }),
    }),

    getOfferingLiabilityRoofImport: builder.query<ConcedusProductImport, number>({
      query: (offeringId) => ({
        url: `/admin/investment-offerings/${offeringId}/liability-roof-import`,
      }),
      providesTags: ['Liability-Roof-Import'],
    }),

    saveOfferingLiabilityRoofImport: builder.mutation<
      ConcedusProductImport,
      { offeringId: number }
    >({
      query: ({ offeringId }) => ({
        url: `/admin/investment-offerings/${offeringId}/liability-roof-import`,
        method: 'POST',
      }),
      invalidatesTags: ['Liability-Roof-Import'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetIssuersQuery,
  useGetInvestmentOfferingsQuery,
  useLazyGetInvestmentOfferingsQuery,
  useUpdateSPVStatusMutation,
  useGetOfferingLiabilityRoofImportQuery,
  useSaveOfferingLiabilityRoofImportMutation,
} = adminApi;
