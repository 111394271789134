import { SVGProps, memo } from 'react';

const SvgEmail16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4965_81772)">
      <path
        d="M13.945 4.9136C13.9302 4.90372 13.9128 4.89844 13.895 4.89844C13.8772 4.89844 13.8598 4.90372 13.845 4.9136L8.97 9.7886C8.84286 9.91619 8.69164 10.0172 8.52513 10.0859C8.35861 10.1546 8.18012 10.1895 8 10.1886C7.6352 10.1879 7.2852 10.0443 7.025 9.7886L2.155 4.9336C2.1402 4.92372 2.1228 4.91844 2.105 4.91844C2.0872 4.91844 2.0698 4.92372 2.055 4.9336C2.04118 4.93906 2.02892 4.94785 2.01933 4.95919C2.00973 4.97053 2.00309 4.98407 2 4.9986V10.9986C2 11.2638 2.10536 11.5182 2.29289 11.7057C2.48043 11.8932 2.73478 11.9986 3 11.9986H13C13.2652 11.9986 13.5196 11.8932 13.7071 11.7057C13.8946 11.5182 14 11.2638 14 10.9986V4.9986C14.0004 4.98055 13.9954 4.9628 13.9856 4.94764C13.9758 4.93248 13.9616 4.92062 13.945 4.9136Z"
        fill="#1F2937"
      />
      <path
        d="M7.5553 9.28C7.67381 9.39515 7.83255 9.45957 7.9978 9.45957C8.16304 9.45957 8.32178 9.39515 8.4403 9.28L13.2853 4.435C13.3179 4.4022 13.3408 4.36101 13.3514 4.31599C13.362 4.27097 13.3599 4.22389 13.3453 4.18C13.2903 4 13.1353 4 13.0003 4H3.0003C2.8603 4 2.7103 4 2.6553 4.18C2.64073 4.22389 2.63863 4.27097 2.64922 4.31599C2.65982 4.36101 2.68269 4.4022 2.7153 4.435L7.5553 9.28Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="clip0_4965_81772">
        <rect width="12" height="12" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgEmail16Px);
export default Memo;
