import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataRoom, DataRoomSteps } from 'src/interfaces/data-room';
import { DataRoomSectionEvaluation } from 'src/interfaces/data-room-evaluation';
import { Venture } from 'src/interfaces/venture';
import { VenturePitch, VenturePitchSteps } from 'src/interfaces/venture-pitch';
import { defineSections } from 'src/ui/investment-offerings/data-room-evaluation/helpers';
import { RootState } from '..';
import initialState from '../initial-store';

const appSlice = createSlice({
  name: 'founder-shared-data',
  initialState: initialState.founderSharedData,
  reducers: {
    setFounderSelectedVenture(state, { payload }: PayloadAction<Venture>) {
      state.founderSelectedVenture = payload;
    },
    setDataRoomSetup(state, { payload }: PayloadAction<DataRoom>) {
      state.dataRoom = payload;
    },
    updateDataRoomSetup(
      state,
      { payload }: PayloadAction<{ data: Partial<DataRoom>; state: DataRoomSteps }>,
    ) {
      if (state.dataRoom) {
        const newState = state.dataRoom.completedStates || [];
        const completedStates = newState.includes(payload.state)
          ? newState
          : [...newState, payload.state];
        state.dataRoom = {
          ...state.dataRoom,
          ...payload.data,
          completedStates,
        };
      }
    },
    setDataRoomEvaluation(state, { payload }: PayloadAction<DataRoomSectionEvaluation[]>) {
      state.dataRoomSectionEvaluation = payload;
    },
    updateDataRoomEvaluation(state, { payload }: PayloadAction<DataRoomSectionEvaluation>) {
      if (state.dataRoomSectionEvaluation) {
        const sections = defineSections(state.dataRoomSectionEvaluation, payload);
        state.dataRoomSectionEvaluation = sections;
      }
    },

    setVenturePitch(state, { payload }: PayloadAction<VenturePitch>) {
      state.venturePitchSetup.venturePitch = payload;

      // set preview to initial data each time when we get new venture pitch
      state.venturePitchSetup.desktopPreview =
        initialState.founderSharedData.venturePitchSetup.desktopPreview;
    },
    updateVenturePitch(
      state,
      { payload }: PayloadAction<{ data: Partial<VenturePitch>; state: VenturePitchSteps }>,
    ) {
      if (state.venturePitchSetup.venturePitch) {
        const newState = state.venturePitchSetup.venturePitch.completedStates || [];
        const completedStates = newState.includes(payload.state)
          ? newState
          : [...newState, payload.state];

        state.venturePitchSetup.venturePitch = {
          ...state.venturePitchSetup.venturePitch,
          ...payload.data,
          completedStates,
        };
      }
    },
    resetVenturePitch(state) {
      state.venturePitchSetup.venturePitch = null;
    },
  },
});

export const {
  setFounderSelectedVenture,
  setDataRoomSetup,
  updateDataRoomSetup,
  setDataRoomEvaluation,
  updateDataRoomEvaluation,
  setVenturePitch,
  updateVenturePitch,
  resetVenturePitch,
} = appSlice.actions;

export default appSlice.reducer;

const selectSelf = (state: RootState) => state;

export const getFounderSelectedVenture = createSelector(
  selectSelf,
  (state: RootState) => state.founderSharedData.founderSelectedVenture,
);

export const getDataRoomSetupSelector = createSelector(
  selectSelf,
  (state: RootState) => state.founderSharedData.dataRoom,
);

export const getDataRoomEvaluationSelector = createSelector(
  selectSelf,
  (state: RootState) => state.founderSharedData.dataRoomSectionEvaluation,
);

export const getVenturePitchSelector = createSelector(
  selectSelf,
  (state: RootState) => state.founderSharedData.venturePitchSetup.venturePitch,
);
export const getDesktopPreviewDataSelector = createSelector(
  selectSelf,
  (state: RootState) => state.founderSharedData.venturePitchSetup.desktopPreview,
);
